import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import login from "./reducer/admin/loginSlice";
import posts from "./reducer/pages/Blog/postsSlice";
import categorysearch from "./reducer/admin/blogCategory";

export const store = configureStore({
  reducer: { login, posts, categorysearch },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  // middleware: [logger],
});

export type Rootstate = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
